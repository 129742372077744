import React, { FC } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { pushDataEvent } from '../../helpers/analytics';
import { Button } from '../Button';
import { TextArea } from '../Inputs/TextArea';
import { TextInput } from '../Inputs/TextInput';

export const ConnectForm: FC = () => {
  const [openSnackbar] = useSnackbar();

  return (
    <section className="mx-auto w-[640px] max-w-full px-5" id="contact_us">
      <div className="mt mx-auto mt-[30px] w-fit text-[24px] tracking-[-2px] lg:text-[32px] xl:text-[44px] font-medium">
        READY TO CONNECT?
      </div>
      <div className="mx-auto mb-10 mt-2 w-fit text-center">
        Simplify, save and ship with an all-in-one enterprise-grade data
        platform.
      </div>
      <Formik
        initialValues={{
          email: '',
          // plan: 'basic',
          name: '',
          companyName: '',
          comments: '',
          picked: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const result = await fetch(
            'https://hooks.slack.com/services/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',
            {
              method: 'POST',
              headers: {
                'Content-Type':
                  'application/x-www-form-urlencoded;charset=UTF-8',
              },
              body: JSON.stringify({
                channel: '#lambda-feed',
                username: 'lambda-feed-bot',
                text: JSON.stringify(values),
                icon_emoji: ':ghost:',
              }),
            }
          );
          if (result.ok) {
            pushDataEvent('get_offer:success', {});
            resetForm();
            openSnackbar(
              'Thank you! Your form has been successfully submitted.'
            );
          } else {
            pushDataEvent('get_offer:error', {});
            openSnackbar('Oops! Something went wrong, please try again later.');
          }
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        })}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form
            className="mx-auto w-[360px] max-w-full"
            onSubmit={handleSubmit}
          >
            <TextInput
              value={values.email}
              name="email"
              onChange={handleChange}
              label="Your Email"
              error={errors.email}
              required
            />
            <TextInput
              value={values.name}
              name="name"
              onChange={handleChange}
              label="Your Name"
              error={errors.name}
            />

            <TextInput
              value={values.companyName}
              name="companyName"
              onChange={handleChange}
              label="Your company name"
              error={errors.companyName}
            />

            <TextArea
              value={values.comments}
              name="comments"
              onChange={handleChange}
              label="Additional comments"
              placeholder="Anything you would like to add"
              error={errors.comments}
            />

            <Button
              text="CONNECT"
              onClick={handleSubmit}
              fullwidth
              textAppearance="light"
              type="submit"
            />
          </Form>
        )}
      </Formik>
      <div className="mx-auto mt-5 w-full text-center">
        or message the 24x7 {' '}
        <a
          href="https://t.me/P2P_staking_support_bot"
          target="_blank"
          className="text-purple"
          rel="noreferrer"
        >
          support
        </a>{' '}
        team
      </div>
    </section>
  );
};
